import React from "react";
import "./Header.css";
const Header = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="logo">
          <img
            src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1666160695/SYC-SYG-Logos-Images/purplesweeptakes-logo_yk8fm3.png"
            className="img-responsive"
          />
        </div>
      </div>
    </div>
  );
};
export default Header;
