import { React, useRef, useState, useEffect } from "react";
import "./style.css";
import "./Button.css";
import Header from "./Header";
import Footer from "./Footer";
import HeaderLower from "./HeaderLower";
import "bootstrap/dist/css/bootstrap.min.css";
import swal from "sweetalert";

import LogService from "../services/log.service";
import RegisterService from "../services/register.service";

function RegisterForm(props) {
  const [fields, setFields] = useState({
    name: "",
    lname: "",
    emailid: "",
    city: "",
    state: "",
    // showroom: "",
    // sales: "",
    address: "",
    phone: "",
  });
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  const [value_userform, setValue_userform] = useState([]);

  const [website, setWebsite] = useState(window.location.href);

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  localStorage.setItem("isRegister", false);
  const ref = useRef();
  useEffect(() => {
    test();
  }, []);

  async function test() {
    let errors = {};

    try {
      //FIXME remove this service if not used
      const response = await RegisterService.getStates();
      var value = response.data.data;
      this.setState({
        value: value,
      });
      if (response.data.status === 429 || response.data.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      if (value.includes("redirect")) {
      } else {
        errors["questionnare"] = response.data.response;
      }
    } catch (err) {
      if (err.status === 429 || err.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
    }
  }

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "state" && value === "true") {
      value = "";
    }
    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const saveErrors = (err) => {
    setErrors(err);
  };

  // const verifyCallback = (response) => {
  //   let errors = {};
  //   if (response) {
  //     setCaptcha({
  //       captcha: true,
  //     });
  //   } else {
  //     setErrors(errors);
  //     return false;
  //   }
  // };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      let data = {
        fname: fields?.name,
        lname: fields?.lname,
        email: fields?.emailid,
        city: fields?.city,
        state: fields?.state,
        // showroom: fields?.showroom,
        // sales: fields?.sales,
        address: fields?.address,
        phone: fields?.phone,
      };

      console.log(" Frist name :", fields?.name);
      console.log("last name :", fields?.lname);
      console.log("email :", fields?.emailid);
      console.log("city :", fields?.city);
      console.log("state :", fields?.state);
      // console.log("showroom :", fields?.showroom);
      // console.log("sales :", fields?.sales);
      console.log("address :", fields?.address);
      console.log("phone :", fields?.phone);

      var username = process.env.REACT_APP_API_USERNAME;
      var password = process.env.REACT_APP_API_PASSWORD;
      try {
        try {
          LogService.createFormLog(website, "POST", data);
        } catch (e) {
          console.log(e);
        }

        const responseuserform = RegisterService.regData(data);
        responseuserform
          .then((response) => {
            LogService.apiResLog(website, JSON.stringify(response.data));

            var valueUserform = response?.data?.status;
            setValue_userform(valueUserform);
            localStorage.removeItem("isRegister");
            swal({
              title: "Successful!",
              text: "Form Submitted!",
              type: "success",
              icon: "success",
            }).then(() => {
              props.history.push({
                pathname: "/TestOurTech_thankyou",
                state: {
                  name: fields.name,
                  lname: fields.lname,
                  emailid: fields.emailid,
                  city: fields.city,
                  state: fields.state,
                  // showroom: fields.showroom,
                  // sales: fields?.sales,
                  address: fields?.address,
                  phone: fields?.phone,
                },
              });
            });
            if (value_userform?.includes("redirect")) {
            }
          })
          .catch((err) => {
            LogService.apiResLog(website, JSON.stringify(err.response.data));

            let errorData = {};
            errorData["name"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.fname &&
              err?.response?.data?.message.fname[0];
            errorData["lname"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.lname &&
              err?.response?.data?.message.lname[0];
            errorData["emailid"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.email &&
              err?.response?.data?.message.email[0];
            errorData["city"] =
              err.response?.data?.message &&
              err.response?.data?.message?.city &&
              err.response?.data?.message?.city[0];
            errorData["state"] =
              err.response?.data?.message &&
              err.response?.data?.message?.state &&
              err.response?.data?.message?.state[0];
            // errorData["showroom"] =
            //   err?.response?.data?.message &&
            //   err?.response?.data?.message.showroom &&
            //   err?.response?.data?.message.showroom[0];
            // errorData["sales"] =
            //   err?.response?.data?.message &&
            //   err?.response?.data?.message.sales &&
            //   err?.response?.data?.message.sales[0];
            errorData["address"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.address &&
              err?.response?.data?.message.address[0];
            errorData["phone"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.phone &&
              err?.response?.data?.message.phone[0];
            errorData["checkederrortwo"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.size &&
              err?.response?.data?.message.size[0];
            saveErrors(errorData);
            ref?.current?.focus();
          });
      } catch (err) {
        if (err.response.status == 429 || err.response.status == "429") {
          alert("Max Limit Reached. Please wait.");
        }
        let errorData = {};
        errorData["name"] = err?.response?.data?.message.fname[0];
        errorData["lname"] = err?.response?.data?.message.lname[0];
        errorData["emailid"] = err?.response?.data?.message.emailid[0];
        errorData["city"] = err?.response?.data?.message.city[0];
        errorData["state"] = err?.response?.data?.message.state[0];
        // errorData["showroom"] = err?.response?.data?.message.showroom[0];
        // errorData["sales"] = err?.response?.data?.message.sales[0];
        errorData["address"] = err?.response?.data?.message.address[0];
        errorData["phone"] = err?.response?.data?.message.phone[0];

        saveErrors(errorData);
      }
    }
  };
  const validate = (e) => {
    let errors = {};
    let isformIsValid = true;
    // if (!captcha) {
    //   isformIsValid = false;
    //   errors["checkederrorcaptcha"] = "Captcha not verified";
    //   saveErrors(errors);
    //   return isformIsValid;
    // }

    return isformIsValid;
  };
  // const onLoadRecaptcha = () => {};

  return (
    <>
      <div className="container-fluid">
        <Header />
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form">
            {/* <h1>Register to score your free Reward Card!</h1>
            <hr /> */}
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label required className="formsub">
                    Address <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    id="address"
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address || ""}
                    onChange={handleChange}
                    style={
                      errors.address && !errors.lname && !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.address && !errors.lname && !errors.name
                        ? ref
                        : null
                    }
                  />
                  <div className="errorMsg">
                    {errors.address && !errors.lname && !errors.name
                      ? errors.address
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone# <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    name="phone"
                    placeholder="Phone"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {errors.phone &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.phone
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    ref={
                      errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="E-mail"
                    value={fields.emailid || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid &&
                    !errors.phone &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    City <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    style={
                      errors.city &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    placeholder="City"
                    ref={
                      errors.city &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    value={fields.city || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.city &&
                    !errors.emailid &&
                    !errors.phone &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.city
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    State <span className="red_point">*</span>
                  </label>

                  <select
                    style={
                      errors.state &&
                      !errors.city &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.state &&
                      !errors.city &&
                      !errors.emailid &&
                      !errors.phone &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    name="state"
                    id="state"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select State</option>
                    <option value="AL">AL</option>
                    <option value="AK">AK</option>
                    <option value="AB">AB</option>
                    <option value="AZ">AZ</option>
                    <option value="AR">AR</option>
                    <option value="BC">BC</option>
                    <option value="CA">CA</option>
                    <option value="CO">CO</option>
                    <option value="CT">CT</option>
                    <option value="DE">DE</option>
                    <option value="DC">DC</option>
                    <option value="FL">FL</option>
                    <option value="GA">GA</option>
                    <option value="HI">HI</option>
                    <option value="ID">ID</option>
                    <option value="IL">IL</option>
                    <option value="IN">IN</option>
                    <option value="IA">IA</option>
                    <option value="KS">KS</option>
                    <option value="KY">KY</option>
                    <option value="LA">LA</option>
                    <option value="ME">ME</option>
                    <option value="MB">MB</option>
                    <option value="MD">MD</option>
                    <option value="MA">MA</option>
                    <option value="MI">MI</option>
                    <option value="MN">MN</option>
                    <option value="MS">MS</option>
                    <option value="MO">MO</option>
                    <option value="MT">MT</option>
                    <option value="NE">NE</option>
                    <option value="NV">NV</option>
                    <option value="NB">NB</option>
                    <option value="NH">NH</option>
                    <option value="NJ">NJ</option>
                    <option value="NM">NM</option>
                    <option value="NY">NY</option>
                    <option value="NL">NL</option>
                    <option value="NC">NC</option>
                    <option value="ND">ND</option>
                    <option value="NT">NT</option>
                    <option value="NS">NS</option>
                    <option value="NU">NU</option>
                    <option value="OH">OH</option>
                    <option value="OK">OK</option>
                    <option value="ON">ON</option>
                    <option value="OR">OR</option>
                    <option value="PA">PA</option>
                    <option value="PE">PE</option>
                    <option value="PR">PR</option>
                    <option value="QC">QC</option>
                    <option value="RI">RI</option>
                    <option value="SK">SK</option>
                    <option value="SC">SC</option>
                    <option value="SD">SD</option>
                    <option value="TN">TN</option>
                    <option value="TX">TX</option>
                    <option value="UT">UT</option>
                    <option value="VT">VT</option>
                    <option value="VA">VA</option>
                    <option value="WA">WA</option>
                    <option value="WV">WV</option>
                    <option value="WI">WI</option>
                    <option value="WY">WY</option>
                    <option value="YT">YT</option>
                  </select>
                  <div className="errorMsg">
                    {errors.state &&
                    !errors.city &&
                    !errors.emailid &&
                    !errors.phone &&
                    !errors.address &&
                    !errors.lname &&
                    !errors.name
                      ? errors.state
                      : null}
                  </div>
                </div>
                {/* <div className="col-md-7">
                  <label>
                    Have you purchase a Purple mattress today?
                    <span className="red_point">*</span>
                  </label>
                  <label className="inline">
                    <input
                      type="radio"
                      id="sales"
                      name="sales"
                      value="Y"
                      onChange={handleChange}
                      style={
                        errors.showroom &&
                        !errors.sales &&
                        !errors.emailid &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red", color: "black" }
                          : { color: "black" }
                      }
                      ref={
                        errors.showroom &&
                        !errors.sales &&
                        !errors.emailid &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                    />{" "}
                    Yes
                  </label>
                  <label className="inline ml-2">
                    <input
                      type="radio"
                      id="sales"
                      name="sales"
                      value="N"
                      onChange={handleChange}
                      style={
                        errors.showroom &&
                        !errors.sales &&
                        !errors.emailid &&
                        !errors.lname &&
                        !errors.name
                          ? { border: "1px solid red", color: "black" }
                          : { color: "black" }
                      }
                      ref={
                        errors.showroom &&
                        !errors.sales &&
                        !errors.emailid &&
                        !errors.lname &&
                        !errors.name
                          ? ref
                          : null
                      }
                    />{" "}
                    No
                  </label>
                  <div className="errorMsg">
                    {errors.sales &&
                    !errors.showroom &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales
                      : null}
                  </div>
                </div> */}
                <br />
                {/* <br />
                <br />
                <div className="overflow-hider">
                  <Recaptcha
                    sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                    render="explicit"
                    verifyCallback={verifyCallback}
                    onloadCallback={onLoadRecaptcha}
                  />
                </div>
                <div className="errorMsg">{errors.checkederrorcaptcha}</div> */}

                <br />
                <div className="clearfix"></div>
              </div>

              <div class="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn1 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default RegisterForm;
