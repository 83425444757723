import React from "react";
import "./HeaderLower.css";
const HeaderLower = () => {
  return (
    <div className="header_lower">
      {/* Try a Purple at a Mattress Firm and Enter to Win a Beach Getaway! <br/>(Retail value $5,000). */}
      {/* Try a Purple at a Levin Furniture and Enter to Win a Hawaii Getaway! <br/>(Retail value $6,500). */}
      Enter to Win!
    </div>
  );
};
export default HeaderLower;
